// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.WeeklyBoard_title__3HC8M {\n  font-size: 1.5rem;\n  font-weight: 600;\n}\n\n[dir] .WeeklyBoard_title__3HC8M {\n  margin-bottom: 1rem;\n}\n\n.WeeklyBoard_container__3kyms {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n.WeeklyBoard_graph__UhT3Z {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n[dir] .WeeklyBoard_graph__UhT3Z {\n  margin-bottom: 20px;\n}\n.WeeklyBoard_graph__UhT3Z h1 {\n  font-size: 24px;\n}\n[dir=ltr] .WeeklyBoard_graph__UhT3Z h1 {\n  padding-left: 40px;\n}\n[dir=rtl] .WeeklyBoard_graph__UhT3Z h1 {\n  padding-right: 40px;\n}", ""]);
// Exports
exports.locals = {
	"title": "WeeklyBoard_title__3HC8M",
	"container": "WeeklyBoard_container__3kyms",
	"graph": "WeeklyBoard_graph__UhT3Z"
};
module.exports = exports;
