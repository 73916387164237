import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-admin';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { setSidebarVisibility } from 'ra-core';
import { useMediaQuery } from '@material-ui/core';
import { sidebarMenuPaddings } from '../../../styles/dimensions';
import getIsRTL from '../../../utils/isRTL';
import Icon, { iconTypes } from '../../../components/Icon';
import styles from './MenuItem.module.scss';
import { IMAGES_URL } from '../../../constants/Images';

const iconsMap = {
  dashboard: iconTypes.Token,
  communityInfo: IMAGES_URL.ICON_EARTH,
  subcommunities: iconTypes.Info_mambers,
  groupInfo: iconTypes.info,
  rules: iconTypes.condition,
  features: iconTypes['settings_community-notifications'],
  categories: iconTypes['New-item_category'],
  brands: iconTypes.brand,
  conditions: iconTypes.buying,
  sizes: iconTypes.size,
  members: iconTypes['Profile-icon'],
  transactions: iconTypes.transactions,
  reports: iconTypes['Message-user'],
  items: iconTypes.Selling,
  wallets: iconTypes.wallet,
  cities: iconTypes.Settings_Location,
  talents: iconTypes.members,
};

const SimpleMenuItem = ({
  // TODO: add prop-types
  /* eslint-disable react/prop-types */
  isHeader = false,
  header,
  iconType,
  path,
  isActiveItem,
  style = {},
  /* eslint-enable */
}) => {
  // TODO: choose device detector instead of material-ui hooks
  const isMobile = useMediaQuery((theme) => {
    return theme.breakpoints.down('xs');
  });
  const dispatch = useDispatch();
  const translate = useTranslate();

  const onClick = () => {
    if (isMobile) {
      dispatch(setSidebarVisibility(false));
    }
  };

  const renderIcon = () => {
    if (header.includes('communityInfo')) {
      return <img src={iconType} alt="icon" width={20} height={20} />;
    }

    return <Icon type={iconType} width={20} height={20} />;
  };

  return (
    <li
      className={cls(styles.linkWrapper, {
        [styles.headerLink]: isHeader,
        [styles.active]: isHeader && isActiveItem,
      })}
    >
      <NavLink
        to={path}
        activeClassName={cls({
          [styles.headerLinkActive]: !isHeader || isActiveItem,
        })}
        style={style}
        onClick={onClick}
        exact
      >
        {isHeader && <span className={cls('icon', styles.icon)}>{renderIcon()}</span>}
        {translate(header)}
      </NavLink>
    </li>
  );
};

const MenuItem = ({ header, path, childrenLinks, sidebarIsOpen, index, isActiveItem }) => {
  const translate = useTranslate();

  const [isMenuItemExpanded, setIsMenuItemExpanded] = useState(isActiveItem);
  const iconType = iconsMap[index];
  const onItemClick = () => {
    setIsMenuItemExpanded(!isMenuItemExpanded);
  };
  const collapseMenuIcon = getIsRTL() ? 'fa-angle-right' : 'fa-angle-left';

  useEffect(() => {
    if (!isActiveItem && isMenuItemExpanded) {
      setIsMenuItemExpanded(false);
    }
    // eslint-disable-next-line
  }, [isActiveItem]);

  if (!childrenLinks.length) {
    return (
      <SimpleMenuItem
        path={path}
        header={header}
        iconType={iconType}
        isActiveItem={isActiveItem}
        isHeader
      />
    );
  }

  return (
    <li className={cls(styles.linkWrapper, styles.headerLink)}>
      {/* eslint-disable-next-line */}
      <a
        className={cls('d-flex', {
          [styles.headerLinkActive]: isActiveItem,
          [styles.collapsed]: isMenuItemExpanded,
        })}
        onClick={onItemClick}
      >
        <span className={cls('icon', styles.icon)}>
          <Icon type={iconType} />
        </span>
        {translate(header)}
        <b className={cls('fa', collapseMenuIcon, styles.caret)} />
      </a>
      <Collapse className={styles.panel} isOpen={isMenuItemExpanded && sidebarIsOpen}>
        <ul>
          {childrenLinks.map((childProps) => {
            return (
              <SimpleMenuItem
                key={childProps.index}
                path={childProps.path}
                header={childProps.header}
                style={{ paddingLeft: sidebarMenuPaddings.secondary }}
                isActiveItem={isActiveItem}
                isHeader={false}
              />
            );
          })}
        </ul>
      </Collapse>
    </li>
  );
};

MenuItem.propTypes = {
  index: PropTypes.string.isRequired,
  path: PropTypes.string,
  header: PropTypes.string.isRequired,
  childrenLinks: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.string,
      header: PropTypes.string,
    }),
  ),
  sidebarIsOpen: PropTypes.bool.isRequired,
  isActiveItem: PropTypes.bool.isRequired,
};

MenuItem.defaultProps = {
  childrenLinks: [],
  path: null,
};

export default MenuItem;
