// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DailyReport_container__ltNPw {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 5rem;\n  gap: 5rem;\n}", ""]);
// Exports
exports.locals = {
	"container": "DailyReport_container__ltNPw"
};
module.exports = exports;
