// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.UniqueParticipants_title__267GB {\n  font-size: 1.5rem;\n  font-weight: 600;\n}\n\n[dir] .UniqueParticipants_title__267GB {\n  margin-bottom: 1rem;\n}\n\n.UniqueParticipants_container__1_2_5 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n.UniqueParticipants_graph__XqwrH {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n[dir] .UniqueParticipants_graph__XqwrH {\n  margin-bottom: 20px;\n}\n.UniqueParticipants_graph__XqwrH h1 {\n  font-size: 24px;\n}\n[dir=ltr] .UniqueParticipants_graph__XqwrH h1 {\n  padding-left: 40px;\n}\n[dir=rtl] .UniqueParticipants_graph__XqwrH h1 {\n  padding-right: 40px;\n}", ""]);
// Exports
exports.locals = {
	"title": "UniqueParticipants_title__267GB",
	"container": "UniqueParticipants_container__1_2_5",
	"graph": "UniqueParticipants_graph__XqwrH"
};
module.exports = exports;
